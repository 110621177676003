<template>
  <div class="taleRecrCss">
    <Navbar :pageName="pageName" />
    <div class="titleContentCss"><div class="titleCss"></div></div>
    <div class="contentMsgCss">
      <div class="leftContentCss">
        <div class="titleAndInputCss" v-for="(item, index) in inputList" :key="index">
          <div class="inputContentCss">
            <div class="iconCss"><svg-icon :icon-class="item.iconClass" width="1.5rem" height="1.5rem" color="#666666" /></div>
            <input v-if="item.inputType === 'text'" type="text" v-model="item.val" :placeholder="item.title" />
            <textarea v-if="item.inputType === 'textarea'" v-model="item.val" />
          </div>
        </div>
        <button class="detailButCss">提交</button>
      </div>
      <div class="rightContentCss">
        <div class="rightTitleCss">招聘职位</div>
        <div class="recrContentCss">
          <div v-for="(item, index) in recrList" :key="index">
            <div class="titleCss">{{item.title}}</div>
            <div class="msgCss" v-for="(msg, i) in item.msgList" :key="i">{{msg}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '@/views/home/components/Navbar.vue'

export default {
  name: 'taleRecr',
  components: {
    Navbar
  },
  data() {
    return {
      pageName: '',
      inputList: [
        { title: '姓名', val: '', iconClass: 'user', inputType: 'text' },
        { title: '职位', val: '', iconClass: 'occupa', inputType: 'text' },
        { title: '电话', val: '', iconClass: 'phone', inputType: 'text' },
        { title: '工作经验', val: '', iconClass: 'experience', inputType: 'text' }
      ],
      recrList: [
        {
          title: 'Web前端开发工程师 2人',
          msgList: [
            '岗位描述：',
            '(1) 辅助进行Web网站的架构设计与系统分析；',
            '(2) 按设计独立进行Web客户端的程序设计或代码编写，对设计中不足部分在程序设计过程中进行改进；',
            '(3) 对所编写的前端代码进行调试、测试与优化；',
            '(4) 进行新工具与环境实验，收集资料；',
            '(5) 配合测试工程师完成测试计划，对研发完成的Web网站进行系统性能测试和/或中试。',
            '任职要求：',
            '(1) 二年或以上Web前端开发经验；',
            '(2) 精通JavaScript编程，精通DIV+CSS、HTML/XML，熟悉Ajax、jQuery和HTML5；',
            '(3) 熟悉NodeJS框架，并能熟练运用；',
            '(4) 能根据产品需求准确完成产品前端展示效果和交互功能；',
            '(5) 熟悉常见Web前端的优化法则，注重代码质量；',
            '(6) 热爱并关注前端技术的发展，熟悉3D技术优先；',
            '(7) 熟悉Linux操作系统；',
            '(8) 能承担高负荷的工作，有良好的理解、沟通能力和书面表达能力，为人诚实、责任心强、工作敬业；',
            '(9)本科及以上，计算机相关专业，1-3年以上开发经验。'
          ]
        },
        {
          title: 'JAVA研发工程师 3人',
          msgList: [
            '岗位描述：',
            '(1)协助系统分析师进行系统分析与设计，包括：技术可行性研究报告、技术需求分析说明书、软件规格说明书、概要设计和详细设计等的编写；',
            '(2)负责按详细设计独立进行程序设计或代码编写，对详细设计中不足部分在程序设计过程中进行改进；',
            '(3)负责对所编写的源代码进行调试、测试，并负责与其它模块进行联调；',
            '(4)指导程序员工作，进行新工具与环境实验，收集资料；',
            '(5)负责配合测试工程师完成测试计划并进行系统性能与产品测试或中试。',
            '任职要求：',
            '(1)掌握基本的网络通信协议如HTTP，socket，TCP/IP；',
            '(2)熟悉Linux和Windows平台上的Java编程，熟练掌握HTML、XML、JavaScript等相关知识；',
            '(3)熟悉Oracle、MongoDB、MySQL等数据库系统，具有一定的数据库管理设计能力；',
            '(4)熟悉UML设计，具备良好的面向对象的分析与设计能力；',
            '(5)能承担高负荷的工作，有良好的理解、沟通能力和书面表达能力，为人诚实、责任心强、工作敬业；',
            '(6)精通软件框架及设计模式，熟悉Java的开源框架，如Spring,Hibernate/ibatis；',
            '(7)本科及以上，计算机相关专业，三年以上开发经验。'
          ]
        },
        {
          title: '嵌入式软件工程师 2人',
          msgList: [
            '岗位描述：',
            '(1)负责工业采集和无线通信类产品的嵌入式软件开发；',
            '(2)负责无线模块的外设接口驱动、硬件相关的底层驱动程序和RF驱动程序的开发、调试；',
            '(3)负责嵌入式产品与服务端平台软件的联调测试工作；',
            '(4)负责嵌入式采集产品与下端传感器、控制器、PLC等第三方产品的协议开发及联调工作。',
            '任职要求：',
            '(1)精通嵌入式C语言编程，有3年以上嵌入式软件开发经验；',
            '(2)熟悉常见的ARM、STM32等芯片架构，有IEEE802.15.4或Zigbee、wifi、433M、BLE、RFID、Lora等相关产品开发经验优先；',
            '(3)熟悉TCP/IP、MQTT、HTTP等网络协议，熟悉MODBUS、PLC等自动化协议类型优先;',
            '(4)勤奋，积极主动工作习惯，责任心强，能适应创业公司长期的加班压力;',
            '(5)本科及以上学历，计算机、通信、自动化等相关专业，有物联网、自动化等领域从业经验优先。'
          ]
        },
        {
          title: '销售工程师 5人',
          msgList: [
            '岗位描述：',
            '(1)负责工业物联网产品解决方案的市场拓展、渠道建设和销售工作，完成销售目标；',
            '(2)向产品经理、研发工程师反馈客户产品需求，具备产品经理思维，不断改进产品；',
            '(3)制定销售计划并按时完成，书面反馈完成情况。负责销售合同的签订、项目执行、回款等工作；',
            '(4)根据不同项目能够独立撰写各类技术方案、创意，撰写调研报告、报价、总结等。',
            '任职要求：',
            '(1)工作踏实认真、有责任心，沟通能力强，认同公司的企业文化和价值观；',
            '(2)具备至少3年以上企业级客户的市场销售经验，有环保、工业设备等行业销售经验者优先，有PLC、组态软件、无线通信等产品销售经验者优先；',
            '(3)有较强的市场感知能力，敏锐地把握市场动态、市场方向的能力；',
            '(4)本科及以上学历，计算机、电子信息、自动化、市场营销等相关专业。'
          ]
        }
      ]
    }
  },
  mounted() {
    this.pageName = this.$route.query.pageName
  }
}
</script>

<style lang="stylus" scoped>
leftWidth = 17rem

.taleRecrCss {
  width 99.8rem
  background-color #333439
  font-size 0.8rem
  .titleContentCss {
    padding 2rem 18rem 0 18rem
    .titleCss {
      width titleWidth
      font-size 2rem
      height 4rem
      line-height 4rem
      border-bottom 1px solid white
    }
  }
  .contentMsgCss {
    padding 2rem 18rem 4rem 18rem
    .leftContentCss {
      float left
      width leftWidth
      padding-right 2.8rem
      .titleAndInputCss {
        margin-top 0.5rem
        width leftWidth
        .inputContentCss {
          width leftWidth
          position relative
          .iconCss {
            position absolute
            top 0.2rem
            right 0.2rem
            height 100%
          }
          input {
            padding 0 2rem 0 0.5rem
            width 14.5rem
            height 2rem
            color #666666
            border none
            outline none // 焦点置于input框内时不显示边框
          }
          textarea {
            padding 0.3rem 2rem 0.3rem 0.5rem
            width 14.5rem
            height 6rem
            color #666666
            border none
            outline none // 焦点置于input框内时不显示边框
          }
        }
      }
      button {
        margin-top 1rem
        width 8rem
        height 2rem
      }
    }
    .rightContentCss {
      float left
      width calc(44rem - 2px)
      border 1px solid white
      color white
      .rightTitleCss {
        width 100%
        text-align center
        font-size 1.2rem
        height 3rem
        line-height 3rem
        font-weight bolder
        background-color #E84C3D
      }
      .recrContentCss {
        padding 1.5rem
        .titleCss {
          font-size 1rem
          font-weight bold
          height 2rem
          line-height 2rem
        }
        .msgCss {
          padding 0.3rem 0
          text-indent 1.6rem
        }
      }
    }
  }
  .contentMsgCss:after { // 使 contentMsgCss 适应内部高度
    clear both
    content ''
    width 0
    height 0
    display block
    visibility hidden
  }
}
</style>
